import React from "react"
import { Link } from "gatsby"
import "../styles/index.css"
import "../styles/utilities.css"
import Flip from "react-reveal/Flip"
import SEO from "../components/seo"
import Webguy from "../images/web.gif"
import Surfing from "../images/surfing.gif"
import Aol from "../images/aol.gif"
import ForEdenAlbumCover from "../images/for-eden-album-cover.jpg"
import Logo from "../images/albumLogo.jpg"
import Liam from "../images/liamHome.png"
import CD from "../images/cd.png"
import Navbar from "../components/navbar"
import Footer from "../components/footer"

const Home = () => {
  const title = "Welcome to Our Church"
  const description =
    "Welcome to the virtual home for The Church of Better Daze. Providing a spiritual and musical home for anyone seeking a better day or a better series of daze. See our weekly announcements, listen to our music, and meet our minister and musician Boy Golden. Become a member of our congregation and receive a special welcome package as well as other perks."

  return (
    <>
      <SEO title={title} description={description} />

      <div className="contact-page-container ">
        <Navbar />
        <div className="content-container ">
          {" "}
          <div className="welcome-content tacky-border3 center-text bg-mandala">
            <h1 className="txt-secondary txt-shadow2 ">
              Welcome to <em>The Church of Better Daze!</em>
            </h1>
            <Flip left>
              <img
                className="gif round-shadow-gif"
                src={Logo}
                alt="Church of Better Daze Logo"
              ></img>
            </Flip>

            <h2 className="txt-highlight h2--smaller">
              <em>
                Providing a spiritual and musical home for anyone seeking a
                better day or a better series of daze.
              </em>
            </h2>
          </div>
          <hr />
          <br />
          <h3 className=" center-text txt-secondary txt-shadow2">
            Announcements!
          </h3>
          <div className="marquee txt-highlight bg-space">
            <div>
              <span className="txt-secondary">
                Boy Golden's latest album <i>For Eden</i> is out now!
              </span>{" "}
              <span className="txt-primary">
                - Check out{" "}
                <a target="_blank" href="https://substack.com/@boygolden">
                  Boy Golden's substack
                </a>{" "}
                for regular church updates and insights!
              </span>{" "}
              <span className="txt-secondary">
                - Come see our church band <Link to="/calendar">live</Link>!
              </span>{" "}
              <span className="txt-primary">
                - Stay crispy, but not too crispy, or you will end up getting
                burnt.
              </span>{" "}
              <span className="txt-highlight">
                -{" "}
                <a target="_blank" href="https://laylo.com/boygolden">
                  Join the congregation today
                </a>
              </span>{" "}
              - Get the{" "}
              <span className="txt-highlight">
                <a
                  target="_blank"
                  href="https://yaboygolden.bandcamp.com/merch"
                >
                  Church merch
                </a>{" "}
                of your dreams!
              </span>
            </div>
          </div>
          <hr />
          <div className=" tacky-border3 album-box album  center-text bg-mandala">
            <h4 className="txt-highlight txt-shadow3 center-text">
              Out Now: <i>For&nbsp;Eden</i> <br />
            </h4>
            <div className="flexBox">
              <img
                className="gif "
                src={ForEdenAlbumCover}
                alt="church album art"
              />
              <div className="sm-padding"></div>
              <div className=" ">
                <div className="">
                  {/* <h4 className="txt-highlight txt-shadow3 center-text">
                    Coming Soon: <i>For&nbsp;Eden</i> <br />
                  </h4> */}
                  <a
                    className="btn"
                    href="https://sixshooterrecords.lnk.to/foredenWE"
                    target="_blank"
                    style={{ width: "275px" }}
                  >
                    Listen Now
                  </a>
                  <a
                    className="btn"
                    target="_blank"
                    href="https://store.sixshooterrecords.com/collections/boy-golden/products/boy-golden-for-eden-1?variant=41418331291718"
                    style={{ width: "275px" }}
                  >
                    Online Store
                  </a>
                  {/* <a
                    className="btn"
                    target="_blank"
                    href="https://sixshooterrecords.lnk.to/heretostay"
                  >
                    Listen to the first single, <br /> <em>Here to Stay</em>
                  </a> */}
                </div>
              </div>

              <span className="cd-animation">
                <img
                  className="gif no-shadow-gif cd-gif "
                  src={CD}
                  alt="Compact Disc"
                ></img>
              </span>
            </div>
            <div
              className="text-left sm-padding bg-white"
              style={{ width: "90%", margin: "auto", maxWidth: "750px" }}
            >
              Utopic and wistful, Boy Golden searches for the best of all
              possible worlds in <i>For Eden</i>, a continuation of his “follow
              your art” proselytizing that began with his{" "}
              <i>Church of Better Daze</i> debut. A counterpoint to 2023’s{" "}
              <i>For Jimmy</i>, <i>For Eden</i> is largely a work of solitude;
              its story begins with an SM57 and a cabin in the woods. Recorded
              entirely to cassette, the album’s origins - analog and
              off-the-grid - speak to the more personal, self-reflective mood of
              the collection. While Boy Golden’s favourite mountain road makes a
              cameo, the album’s country waltzes, banjo and mandolin-laced
              acoustics glow softly under the light of a pink moon. Chill with
              intention and press play on For Eden, out July 19, 2024.
            </div>
          </div>
          <div className=" flexBox membership-box tacky-border4 album-box center-text">
            <h3 className="  txt-white txt-shadow2 ">
              Become a member of the congregation!
            </h3>
            <div>
              <a target="_blank" href="https://laylo.com/boygolden">
                <button>Join Today!</button>
              </a>
            </div>
            <h6 className="txt-white     sm-padding">
              Sign up to receive early tickets, exclusive merch, never-seen
              before content and more!
            </h6>
          </div>
          <div className="flexBox minister tacky-border4 album-box  center-text">
            <img
              className="gif"
              src={Liam}
              alt="Ya Boy Golden, Musician and Minister at The Church of Better Daze"
            ></img>
            <div className="box-column txt-secondary ">
              <Link to="/about">
                <h3 className="txt-highlight txt-shadow2">Meet Our Minister</h3>
              </Link>
              <h6 className="txt-alt pd-bottom ">
                Boy Golden is the minister and principal songwriter at the
                Church of Better Daze, read the <Link to="/about">bio</Link> to
                learn more!
              </h6>
            </div>
          </div>
          <div className=" flexBox gif-box tacky-border2 bg-mandala">
            <h4 className="center-text txt-highlight txt-shadow2">
              What are you waiting for? Get Surfing!
            </h4>
            <img className="gif" src={Webguy} alt="boy surfing the web gif" />
            <img
              className="gif"
              src={Surfing}
              alt="literal surfing dodging computers gif"
            />
            <img
              className="gif"
              src={Aol}
              alt="American Online 90s advertisement gif"
            />
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Home
